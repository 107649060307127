<template>
    <div id="values" class=" pt-24 pb-24 py-24">
        <div class="container max-w-screen-xl">
            <!-- Section title -->
            <div class="row justify-content-center text-md-center mb-24">
                <div class="col-lg-7">
                    <!-- Surtitle -->
                    <h5 class="h5 mb-5 text-uppercase text-primary">
                        Our Values
                    </h5>
                    <!-- Heading -->
                    <h1 class="ls-tight font-bolder mb-5">
                        Operational values
                    </h1>
                    <!-- Text -->
                    <p class="lead">
                       What keeps us motivated? Well, we're so glad you asked!
                    </p>
                </div>
            </div>
            <!-- Features -->
            <div class="row g-16 g-md-24">
                <div class="col-md-4 mb-5 mb-md-0 text-center">
                    <!-- Icon -->
                    <div class="icon icon-shape icon-lg bg-soft-primary text-primary text-lg rounded-circle mb-7">
                        <i class="bi bi-lightbulb"></i>
                    </div>
                    <!-- Title -->
                    <h5 class="h4 font-semibold mb-2">Innovation</h5>
                    <!-- Text -->
                    <p class="lh-relaxed">
                        We're not boring, and so we constantly strive to push the boundaries of our ingenuity further and further into the cutting-edge.
                    </p>
                </div>
                <div class="col-md-4 mb-5 mb-md-0 text-center">
                    <!-- Icon -->
                    <div class="icon icon-shape icon-lg bg-soft-primary text-primary text-lg rounded-circle mb-7">
                        <i class="bi bi-globe"></i>
                    </div>
                    <!-- Title -->
                    <h5 class="h4 font-semibold mb-2">Accessibility</h5>
                    <!-- Text -->
                    <p class="lh-relaxed">
                        We strongly believe that the web and its wealth of resources should be open and accessible to all, and we're committed to making it so.
                    </p>
                </div>
                <div class="col-md-4 mb-5 mb-md-0 text-center">
                    <!-- Icon -->
                    <div class="icon icon-shape icon-lg bg-soft-primary text-primary text-lg rounded-circle mb-7">
                        <i class="bi bi-people"></i>
                    </div>
                    <!-- Title -->
                    <h5 class="h4 font-semibold mb-2">Community</h5>
                    <!-- Text -->
                    <p class="lh-relaxed">
                        We believe in contributing to the growth and development of open-source communities to ensure a steady flow of creative juices that fuels our collective progress.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Values'
    }
</script>
