<template>
    <section id="milestones" class="py-24 bg-surface-secondary">
        <div class="container max-w-screen-xl">
            <div class="row align-items-center">
                <div class="col-lg-6 col-xl-5 mb-4 mb-lg-0">
                    <!-- Surtitle -->
                    <h5 class="h5 mb-5 text-uppercase text-primary">
                        Milestones
                    </h5>
                    <!-- Title -->
                    <h1 class="ls-tight mb-10">
                        Small and Agile - <span class="text-muted">We've been busy trying to solve some puzzling real-world problems; but not without coffee, of course.</span>
                    </h1>
                    <!-- Milestones -->
                    <div class="row mx-n2">
                        <div class="col-sm-4 px-2 mb-3 mb-sm-0">
                            <div class="card bg-primary shadow rounded-lg border-0">
                                <div class="px-4 py-5 text-center">
                                    <h2 class="text-white mb-1">
                                        <span>10</span>
                                        <span class="counter-extra">+</span>
                                    </h2>
                                    <p class="text-sm text-white mb-0">
                                        Projects
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 px-2 mb-3 mb-sm-0">
                            <div class="card bg-primary shadow rounded-lg border-0">
                                <div class="px-4 py-5 text-center">
                                    <h2 class="text-white mb-1">
                                        <span>100</span>
                                        <span class="counter-extra">+</span>
                                    </h2>
                                    <p class="text-sm text-white mb-0">
                                        Features
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 px-2 mb-3 mb-sm-0">
                            <div class="card bg-primary shadow rounded-lg border-0">
                                <div class="px-4 py-5 text-center">
                                    <h2 class="text-white mb-1">
                                        <span>1</span>
                                        <span class="counter-extra">M+</span>
                                    </h2>
                                    <p class="text-sm text-white mb-0">
                                        Coffee cups
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 ms-lg-auto">
                    <div class="position-relative">
                        <!-- Image -->
                        <figure>
                            <img alt="..." src="https://images.unsplash.com/photo-1488751045188-3c55bbf9a3fa?ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=800&amp;h=700&amp;q=60" class="img-fluid rounded-4 shadow-4">
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'Milestones'
    }
</script>
