<template>
    <div id="home">
        <Hero/>
        <Values/>
        <Milestones/>
        <Services/>
        <Testimonials/>
        <Team/>
    </div>
</template>

<script>
    import Hero from './partials/Hero.vue'
    import Values from './partials/Values.vue'
    import Milestones from './partials/Milestones.vue'
    import Services from './partials/Services.vue'
    import Testimonials from './partials/Testimonials.vue'
    import Team from './partials/Team.vue'

    export default {
        name: 'Index',
        components: {
            Hero,
            Values,
            Milestones,
            Services,
            Testimonials,
            Team
        }
    }
</script>
