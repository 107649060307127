<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-white px-0 py-3">
        <div class="container-xl max-w-screen-xl">
            <!-- Logo -->
            <a class="navbar-brand" href="/">
                <img src="../../assets/images/polycle-logo-no-bg.png" alt="Polycle logo">
            </a>
            <!-- Navbar toggle -->
            <button @click="toggleMobileMenu" class="navbar-toggler" type="button" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <i v-if="mobileMenuIsOpen" ref="mobile-menu-opener" class="bi bi-x-lg"></i>
                <i ref="mobile-menu-closer" class="bi bi-list" v-else></i>
            </button>

            <!-- Collapse -->
            <transition name="fade">
                <div v-show="mobileMenuIsOpen" :class="{ 'd-block': mobileMenuIsOpen }" class="collapse navbar-collapse justify-content-end" id="navbarCollapse">
                    <!-- Nav -->
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a class="nav-link text-center" href="#home">Home</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-center" href="#values">Values</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-center" href="#milestones">Milestones</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-center" href="#services">Services</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link text-center" href="#testimonials">Testimonials</a>
                        </li>

                        <li class="nav-item">
                            <a class="nav-link text-center" href="#contact">Get in touch <i class="bi bi-arrow-right"></i></a>
                        </li>
                    </ul>
                </div>
            </transition>
        </div>
    </nav>
</template>

<script>
    export default {
        name: 'Nav',
        data() {
            return {
                mobileMenuIsOpen: false
            }
        },
        methods: {
            toggleMobileMenu() {
                this.mobileMenuIsOpen = ! this.mobileMenuIsOpen
            }
        }
    }
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.25s ease-out;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .navbar-light .navbar-toggler:focus {
         color: gray;
         background-color: transparent;
    }

    @media (min-width: 992px) {
        .navbar-expand-lg .navbar-brand img {
            height: 100%;
        }
    }
</style>
