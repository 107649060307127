<template>
    <section id="team" class="py-20">
        <div class="container max-w-screen-xl">
            <!-- Section title -->
            <div class="row justify-content-center text-center mb-20">
                <div class="col-lg-7">
                    <!-- Surtitle -->
                    <h5 class="h5 mb-5 text-uppercase text-primary">
                        Control tower
                    </h5>
                    <!-- Title -->
                    <h1 class="ls-tight font-bolder display-5 mb-5">
                        Meet The Wizard of [our] Oz!
                    </h1>
                    <!-- Text -->
                    <p class="lead text-muted">
                        Ideas are like jig-saw puzzles to me; the genius of it isn't always obvious right away, so I like to try to make the pieces fit to reveal the beautiful picture once buried under the rubble of chaos and uncertainties.
                    </p>
                </div>
            </div>
            <!-- Content -->
            <div class="row justify-content-center">
                <div class="col-xl-8">
                    <div class="row g-10">
                        <div class="col-12 text-center mb-5">
                            <div class="d-flex flex-column align-items-center">
                                <!-- Image -->
                                <img alt="CEO's photo" class="w-40 h-auto rounded-circle mb-5" src="../../assets/images/ceo.jpg">
                                <!-- Title -->
                                <h4 class="mb-1">David Abiola</h4>
                                <!-- Subtitle -->
                                <p class="text-muted text-sm">CEO / Software Engineer</p>
                                <!-- Social Links -->
                                <div class="mx-n1 mt-4">
                                    <a class="p-2 text-muted mx-1" target="_blank" href="https://twitter.com/@AlienBrown">
                                        <svg width="1em" height="1em" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" class="svg-inline--fa fa-twitter fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
                                        </svg>
                                    </a>
                                    <a class="p-2 text-muted mx-1" target="_blank" href="https://daveabiola.medium.com/">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-medium" viewBox="0 0 16 16">
                                            <path d="M9.025 8c0 2.485-2.02 4.5-4.513 4.5A4.506 4.506 0 0 1 0 8c0-2.486 2.02-4.5 4.512-4.5A4.506 4.506 0 0 1 9.025 8zm4.95 0c0 2.34-1.01 4.236-2.256 4.236-1.246 0-2.256-1.897-2.256-4.236 0-2.34 1.01-4.236 2.256-4.236 1.246 0 2.256 1.897 2.256 4.236zM16 8c0 2.096-.355 3.795-.794 3.795-.438 0-.793-1.7-.793-3.795 0-2.096.355-3.795.794-3.795.438 0 .793 1.699.793 3.795z"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center mt-10">
                <div class="col-auto">
                    <div class="position-relative px-6 py-4 rounded text-heading font-semibold border-2 border-dashed">Want to join our team?
                        <a href="mailto:hello@polycle.com" class="stretched-link">Send your resume to hello@polycle.com</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Team from './Team.vue'

    export default {
        name: Team
    }
</script>
