<template>
    <section id="services" class="py-24">
        <div class="container">
            <div class="row g-10 g-lg-20 justify-content-around align-items-center">
                <div class="col-lg-6 order-lg-2 ms-lg-auto">
                    <!-- Heading -->
                    <h1 class="ls-tight font-bolder mb-5">
                        Our awesome team will help you <span class="text-primary">build a mansion on the web</span>, if that's what you're into
                    </h1>
                    <!-- Text -->
                    <p class="lead lh-loose text-muted mb-7">
                        We speak many languages; including Klingon, and we can teach them to you as well. We're into technologies like:
                    </p>
                    <!-- List -->
                    <ul class="list-unstyled">
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div class="icon icon-xs text-sm icon-shape bg-soft-success text-success rounded-circle me-3">
                                        <i class="bi bi-check"></i>
                                    </div>
                                </div>
                                <div>
                                <span class="text-heading font-semibold mb-0">
                                    Frontend development (with JS frameworks like Vue.js)
                                </span>
                                </div>
                            </div>
                        </li>
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div class="icon icon-xs text-sm icon-shape bg-soft-success text-success rounded-circle me-3">
                                        <i class="bi bi-check"></i>
                                    </div>
                                </div>
                                <div>
                                <span class="text-heading font-semibold mb-0">
                                    Backend development (with PHP frameworks like Laravel)
                                </span>
                                </div>
                            </div>
                        </li>
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div class="icon icon-xs text-sm icon-shape bg-soft-success text-success rounded-circle me-3">
                                        <i class="bi bi-check"></i>
                                    </div>
                                </div>
                                <div>
                                <span class="text-heading font-semibold mb-0">
                                    Database management, DevOps (server deployment and maintenance)
                                </span>
                                </div>
                            </div>
                        </li>
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div class="icon icon-xs text-sm icon-shape bg-soft-success text-success rounded-circle me-3">
                                        <i class="bi bi-check"></i>
                                    </div>
                                </div>
                                <div>
                                <span class="text-heading font-semibold mb-0">
                                    API development, and more...
                                </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-6 order-lg-1">
                    <!-- Image -->
                    <div class="position-relative zindex-100">
                        <img alt="..." src="https://images.unsplash.com/photo-1498049860654-af1a5c566876?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1000&amp;h=900&amp;q=80" class="img-fluid rounded-lg shadow-lg">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'Services'
    }
</script>
